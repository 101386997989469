export default {
  actions: {
    label: 'Actions',
    edit: 'Edit',
    save: 'Save',
    update: 'Update',
    complete: 'Complete',
    delete: 'Delete',
    cancel: 'Cancel',
    new: 'New',
    list: 'List',
    search: 'Search',
    confirmDelete: 'Delete Confirmation',
    next: 'Next',
    previous: 'Previous',
    page: 'Page',
    add: 'Add',
    view: 'View',
    logout: 'logout',
  },
}
