export default {
  medications: {
    label: 'Medications',
    filterTitle: 'Filter by status',
    search: 'Search Medications',
    status: {
      draft: 'Draft',
      active: 'Active',
      onHold: 'On Hold',
      canceled: 'Canceled',
      completed: 'Completed',
      enteredInError: 'Entered In Error',
      stopped: 'Stopped',
      unknown: 'Unknown',
    },
    intent: {
      proposal: 'Proposal',
      plan: 'Plan',
      order: 'Order',
      originalOrder: 'Original Order',
      reflexOrder: 'Reflex Order',
      fillerOrder: 'Filler Order',
      instanceOrder: 'Instance Order',
      option: 'Option',
    },
    priority: {
      routine: 'Routine',
      urgent: 'Urgent',
      asap: 'Asap',
      stat: 'Stat',
    },
    filter: {
      all: 'All statuses',
    },
    requests: {
      label: 'Medication Requests',
      new: 'Request Medication',
      view: 'View Medication',
      cancel: 'Cancel Medication',
      complete: 'Complete Medication',
      update: 'Update Medication',
      error: {
        unableToRequest: 'Unable to create Medication request.',
        unableToComplete: 'Unable to complete Medication request.',
        quantityRequired: 'Quantity is required.',
        unitRequired: 'Unit is required.',
      },
    },
    medication: {
      medication: 'Medication',
      for: 'For',
      status: 'Status',
      intent: 'Intent',
      priority: 'Priority',
      notes: 'Notes',
      quantity: 'Quantity',
      quantityValue: 'Value',
      quantityUnit: 'Unit',
      requestedOn: 'Requested On',
      requestedBy: 'Requested By',
      completedOn: 'Completed On',
      canceledOn: 'Canceled On',
      patient: 'Patient',
    },
  },
}
