export default {
  actions: {
    label: 'Hành động',
    edit: 'Chỉnh sửa',
    save: 'Lưu',
    update: 'Cập nhật',
    complete: 'Hoàn thành',
    delete: 'Xóa',
    cancel: 'Thoát',
    new: 'Mới',
    list: 'Danh sách',
    search: 'Tìm kiếm',
    confirmDelete: 'Xác nhận xóa bỏ',
    next: 'Tiếp theo',
    previous: 'Phía trước',
    page: 'Trang',
    add: 'Thêm',
    view: 'Xem',
    logout: 'Đăng xuất',
},
}
