export default {
  scheduling: {
    label: 'Thời gian biểu',
    appointments: {
      label: 'Lịch Hẹn',
      new: 'Lịch hẹn mới',
      schedule: 'Thời gian hẹn',
      editAppointment: 'Chỉnh sửa lịch hẹn',
      createAppointment: 'Tạo mới lịch hẹn',
      deleteAppointment: 'Xóa lịch hẹn',
      viewAppointment: 'Xem lịch hẹn',
      updateAppointment: 'Cập nhật lịch hẹn',
    },
    appointment: {
      startDate: 'Ngày bắt đầu',
      endDate: 'Ngày kết thúc',
      location: 'Địa điểm',
      type: 'Loại',
      types: {
        checkup: 'Kiểm tra',
        emergency: 'Khẩn cấp',
        followUp: 'Theo dõi',
        routine: 'Công việc hằng ngày',
        walkIn: 'Đi vào',
      },
      errors: {
        createAppointmentError: 'Không thể tạo mới lịch hẹn',
        updateAppointmentError: 'Không thể cập nhật lịch hẹn',
        patientRequired: 'Bệnh nhân là bắt buộc',
        startDateMustBeBeforeEndDate: 'Thời gian bắt đầu nhỏ hơn thời gian kết thúc',
      },
      reason: 'Lý do',
      patient: 'Bệnh nhân',
      deleteConfirmationMessage: 'Bạn có chắc chắn muốn xóa tin nhắn này!',
      successfullyCreated: 'Tạo thành công lịch hẹn',
      successfullyDeleted: 'Xóa thành công lịch hẹn',
      successfullyUpdated: 'Cập nhật thành công lịch hẹn',
    },
  },
}
