import translationVi from './vi/translations'

import translationEnUs from './enUs/translations'


const resources: { [language: string]: any } = {
  vi: {
    name: 'Tiếng việt',
    translation: translationVi,
  },
  en: {
    name: 'English, American',
    translation: translationEnUs,
  }
}

export default resources
