export default {
  patients: {
    label: 'Bệnh nhân',
    warning: 'Cảnh báo!',
    patientsList: 'Danh sách bệnh nhân',
    viewPatients: 'Xem danh sách bệnh nhân',
    noPatients: "Chưa có mục bệnh nhân nào! Hãy thêm một mục",
    viewPatient: 'Xem bệnh nhân',
    createPatient: 'Tạo hồ sơ mới',
    newPatient: 'Hồ sơ mới',
    editPatient: 'Chỉnh sửa hồ sơ',
    updatePatient: 'Cập nhật hồ sơ',
    successfullyCreated: 'Tạo mới hồ sơ thành công',
    successfullyAddedNote: 'Tạo thành công ghi chú mới',
    successfullyAddedRelatedPerson: 'Đã thêm thành công người có liên quan mới',
    successfullyUpdated: 'Thông tin cập nhật thành công cho ',
    possibleDuplicatePatient: 'Bệnh nhân có thể trùng lặp:',
    duplicatePatientWarning:
      'Bệnh nhân với thông tin phù hợp được tìm thấy trong cơ sở dữ liệu. Bạn có chắc chắn muốn tạo bệnh nhân này không?',
  },
}
