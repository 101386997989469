export default {
  patient: {
    label: 'Bệnh nhân',
    code: 'Mã bệnh nhân',
    firstName: 'Họ',
    lastName: 'Tên',
    suffix: 'Tên đệm',
    prefix: 'Tiền tố',
    givenName: 'Tên gọi',
    familyName: 'Họ',
    dateOfBirth: 'Ngày sinh',
    approximateDateOfBirth: 'Ngày sinh phỏng đoán',
    age: 'Tuổi',
    approximateAge: 'Tuổi phỏng đoán',
    placeOfBirth: 'Nơi sinh',
    sex: 'Giới tính',
    bloodType: 'Nhóm máu',
    contactInfoType: {
      label: 'Loại',
      options: {
        home: 'Nhà riêng',
        mobile: 'Di động',
        work: 'Nơi làm việc',
        temporary: 'Tạm trú',
        old: 'Old',
      },
    },
    phoneNumber: 'Số điện thoại',
    email: 'Email',
    address: 'Địa chỉ',
    occupation: 'Nghề nghiệp',
    type: 'Loại hồ sơ',
    preferredLanguage: 'Ngôn ngữ ưa thích',
    basicInformation: 'Thông tin cơ bản',
    generalInformation: 'Thông tin thông thường',
    contactInformation: 'Thông tin liên hệ',
    unknownDateOfBirth: 'Không xác định',
    relatedPerson: 'Người liên quan',
    relatedPersons: {
      error: {
        unableToAddRelatedPerson: 'Không thể thêm người liên quan',
        relatedPersonRequired: 'Người liên quan là bắt buộc',
        relationshipTypeRequired: 'Loại quan hệ là bắt buộc',
      },
      label: 'Người liên quan',
      new: 'Người liên quan mới',
      add: 'Thêm người liên quan',
      relationshipType: 'Loại quan hệ',
      warning: {
        noRelatedPersons: 'Không có người liên quan',
      },
      addRelatedPersonAbove: 'Thêm người có liên quan bằng cách sử dụng nút ở trên.',
    },
    appointments: {
      new: 'Thêm lịch hẹn',
      warning: {
        noAppointments: 'Không có lịch hẹn',
      },
      addAppointmentAbove: 'Thêm một cuộc hẹn bằng cách sử dụng nút ở trên.',
    },
    allergies: {
      label: 'Dị ứng',
      allergyName: 'Tên loại dị ứng',
      new: 'Thêm',
      error: {
        nameRequired: 'Tên là bắt buộc',
        unableToAdd: 'Không thể thêm mới',
      },
      warning: {
        noAllergies: 'Không có loại dị ứng',
      },
      addAllergyAbove: 'Thêm loại dị ứng bằng nút ở trên',
      successfullyAdded: 'Thêm thành công',
    },
    diagnoses: {
      label: 'Chẩn đoán',
      new: 'Thêm mới chẩn đoán',
      diagnosisName: 'Tên',
      diagnosisDate: 'Ngày tháng',
      onsetDate: 'Ngày bắt đầu',
      abatementDate: 'Ngày bác bỏ',
      visit: 'Visit',
      status: 'Trạng thái',
      active: 'Hoạt động',
      recurrence: 'Recurrence',
      relapse: 'Tái phát',
      inactive: 'Không hoạt động',
      remission: 'Remission',
      resolved: 'Miễn trừ',
      note: 'Ghi chú',
      warning: {
        noDiagnoses: 'Không có chẩn đoán',
      },
      error: {
        nameRequired: 'Tên là bắt buộc',
        dateRequired: 'Ngày là bắt buộc',
        unableToAdd: 'Không thể thêm mới',
      },
      addDiagnosisAbove: 'Add a diagnosis using the button above.',
      successfullyAdded: 'Successfully added a new diagnosis!',
    },
    note: 'Ghi chú',
    notes: {
      label: 'Ghi chú',
      new: 'Thêm ghi chú mới',
      warning: {
        noNotes: 'Không có ghi chú',
      },
      error: {
        noteRequired: 'Ghi chú là bắt buộc',
        unableToAdd: 'Không thể thêm ghi chú mới',
      },
      addNoteAbove: 'Add a note using the button above.',
    },
    labs: {
      label: 'Phòng xét nghiệm',
      new: 'Thêm xét nghiệm mới',
      warning: {
        noLabs: 'Chưa có xét nghiệm',
      },
      noLabsMessage: 'Không yêu cầu xét nghiệm đối với bệnh nhân này',
    },
    medications: {
      label: 'Thuốc',
      new: 'Thuốc mới',
      warning: {
        noMedications: 'Không có thuốc',
      },
      noMedicationsMessage: 'Không yêu cầu thuốc đối với bệnh nhân này',
    },
    careGoal: {
      new: 'Thêm mục tiêu điều trị',
      label: 'Mục tiêu điều trị',
      title: 'Tiêu đề',
      description: 'Mô tả',
      status: 'Trạng thái',
      achievementStatus: 'Thành tích',
      priority: {
        label: 'Ưu tiên',
        low: 'Thấp',
        medium: 'Trung bình',
        high: 'Cao',
      },
      startDate: 'Ngày bắt đầu',
      dueDate: 'Ngày kết thúc',
      note: 'Ghi chú',
      error: {
        unableToAdd: 'Không thể thêm mới',
        descriptionRequired: 'Mô tả là bắt buộc',
        priorityRequired: 'Sự ưu tiên là bắt buộc',
        statusRequired: 'Trạng thái là bắt buộc',
        achievementStatusRequired: 'Thành tích là bắt buộc',
        startDate: 'Ngày bắt đầu là bắt buộc',
        dueDate: 'Ngày kết thúc là bắt buộc',
        dueDateMustBeAfterStartDate: 'Ngày kết thúc phải sau ngày bắt đầu',
      },
    },
    careGoals: {
      warning: {
        noCareGoals: 'Không có mục tiêu điều trị',
        addCareGoalAbove: 'Thêm mới mục tiêu điều trị bằng nút trên',
      },
    },
    carePlan: {
      new: 'Thêm kế hoạch điều trị',
      label: 'Kế hoạch điều trị',
      title: 'Tiêu đề',
      description: 'Mô tả',
      status: 'Trạng thái',
      condition: 'Điều kiện',
      intent: 'Ý định',
      startDate: 'Ngày bắt đầu',
      endDate: 'Ngày kết thúc',
      note: 'Ghi chú',
      error: {
        unableToAdd: 'Không thể thêm mới',
        titleRequired: 'Tiêu đề là bắt buộc',
        descriptionRequired: 'Description is required.',
        conditionRequired: 'Condition is required.',
        statusRequired: 'Status is required.',
        intentRequired: 'Intent is required.',
        startDate: 'Start date is required.',
        endDate: 'End date is required',
      },
    },
    carePlans: {
      warning: {
        noCarePlans: 'No Care Plans',
        addCarePlanAbove: 'Add a care plan using the button above.',
      },
    },
    visit: 'Visit',
    visits: {
      new: 'Add Visit',
      label: 'Visits',
      startDateTime: 'Start Date',
      endDateTime: 'End Date',
      type: 'Type',
      status: 'Status',
      reason: 'Reason',
      location: 'Location',
      error: {
        unableToAdd: 'Unable to add a new visit.',
        startDateRequired: 'Start date is required.',
        endDateRequired: 'End date is required',
        endDateMustBeAfterStartDate: 'End date must be after start date',
        typeRequired: 'Type is required.',
        statusRequired: 'Status is required.',
        reasonRequired: 'Reason is required.',
        locationRequired: 'Location is required.',
      },
      warning: {
        noVisits: 'No Visits',
        addVisitAbove: 'Add a visit using the button above.',
      },
    },
    types: {
      charity: 'Charity',
      private: 'Private',
    },
    errors: {
      createPatientError: 'Could not create new patient.',
      updatePatientError: 'Could not update patient.',
      patientGivenNameFeedback: 'Given Name is required.',
      patientDateOfBirthFeedback: 'Date of Birth can not be greater than today',
      patientNumInSuffixFeedback: 'Cannot contain numbers.',
      patientNumInPrefixFeedback: 'Cannot contain numbers.',
      patientNumInFamilyNameFeedback: 'Cannot contain numbers.',
      patientNumInPreferredLanguageFeedback: 'Cannot contain numbers.',
      invalidEmail: 'Must be a valid email.',
      invalidPhoneNumber: 'Must be a valid phone number.',
    },
  },
}
